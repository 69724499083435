<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Изменить заказ">{{ $t('message.update_product') }}</span>
      <el-button type="success" size="small" class="mr-1" @click="submitForm()">{{ $t('message.save') }}</el-button>
      <el-button
        type="primary"
        size="small"
        class="mr-1"
        @click="submitForm(true)"
      >{{ $t('message.save_and_create_new') }}</el-button>
      <el-button type="warning" icon="el-icon-close" size="small" @click="close()">{{ $t('message.close') }}</el-button>
    </header>

    <div class="body__modal">
      <el-form ref="form" :model="form" :rules="rules" size="small" class="aticler_m stylekhanForm">
        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="d-flex">
                <div class="text_a mr-3">{{ $t('message.buy_ready_product') }} № {{ $t('message.from') }}</div>
                <el-form-item :label="columns.date.title" prop="date">
                  <el-date-picker
                    v-model="form.date"
                    type="date"
                    :placeholder="columns.date.title"
                    :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
            <!-- end  col -->
            <el-col :span="12" class="text-right">
              <span style="color: #6c757d!important;">{{ $t('message.username') }}</span>
              {{ username }}
            </el-col>
          </el-row>
          <!-- end el-row -->

          <el-row :gutter="40">
            <el-col :span="8">
              <el-form-item :label="columns.supplier_id.title" prop="supplier_id">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <el-select
                        v-model="form.supplier_id"
                        :placeholder="columns.supplier_id.title"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="item in suppliers"
                          :key="item.id + item.name"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="mb-0">
                    <div class="plusic" @click="drawer.provider.status = true">
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- end el-form-item-->

              <el-form-item :label="columns.supplier_contract_id.title" prop="supplier_contract_id">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <el-select
                        v-model="form.supplier_contract_id"
                        :placeholder="columns.supplier_contract_id.title"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="item in supplier_contracts"
                          :key="item.id + item.number"
                          :label="item.number"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="mb-0">
                    <div class="plusic" @click="drawer.contract.status = true">
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- end el-form-item-->
            </el-col>
            <!-- end  col -->

            <el-col :span="8">
              <el-form-item :label="columns.delivery_deadline.title" prop="delivery_deadline">
                <el-date-picker
                  v-model="form.delivery_deadline"
                  type="date"
                  :placeholder="columns.delivery_deadline.title"
                  :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
                ></el-date-picker>
              </el-form-item>
              <!-- end el-form-item-->

              <el-form-item :label="columns.status_id.title" prop="status_id">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <el-select
                        v-model="form.status_id"
                        :placeholder="columns.status_id.title"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="item in statuses"
                          :key="item.id + item.title"
                          :label="item.title"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- end el-form-item-->
            </el-col>
            <!-- end  col -->

            <el-col :span="8">
              <!-- end el-form-item-->
              <el-form-item :label="columns.description.title" prop="description">
                <el-input
                  type="textarea"
                  v-model="form.description"
                  :placeholder="columns.description.title"
                ></el-input>
              </el-form-item>
              <!-- end el-form-item-->
            </el-col>
            <!-- end  col -->
          </el-row>
          <!-- end el-row -->
        </el-card>

        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-table border :data="procurementProducts">
                <el-table-column label="#" width="30">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.name')">
                  <template slot-scope="scope">{{ scope.row.name }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.quantity')">
                  <template slot-scope="scope">
                    <el-input
                      min="1"
                      type="number"
                      v-model="scope.row.quantity"
                      size="mini"
                      :disabled="scope.row.isOld"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.measurement')">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.measurement_id"
                      filterable
                      clearable
                      size="mini"
                      :disabled="scope.row.isOld"
                    >
                      <el-option
                        v-for="item in measurements"
                        :key="item.id + item.name"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.price2')">
                  <template slot-scope="scope">
                    <el-input
                      min="0"
                      type="number"
                      v-model="scope.row.price"
                      size="mini"
                      :disabled="scope.row.isOld"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.currency')">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.currency_id"
                      filterable
                      clearable
                      size="mini"
                      :disabled="scope.row.isOld"
                    >
                      <el-option
                        v-for="item in currencies"
                        :key="item.id + item.name"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.rate')">
                  <template slot-scope="scope">
                    <el-input
                      min="0"
                      type="number"
                      v-model="scope.row.rate"
                      size="mini"
                      :disabled="scope.row.isOld"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.actions')">
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeProduct(scope.row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
            <!-- end  col -->
            <el-col :span="12">
              <el-form-item class="mb-0">
                <el-select
                  :placeholder="$t('message.products')"
                  filterable
                  clearable
                  @input="selectProduct"
                  v-model="selectedProduct"
                >
                  <el-option
                    v-for="item in products"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- end  col -->
            <el-col :span="12">
              <span class="font-weight-bold">Итого: {{ Math.round(getTotal) }} $</span>
            </el-col>
            <!-- end  col -->
          </el-row>
        </el-card>
      </el-form>
    </div>

    <el-drawer
      size="50%"
      :append-to-body="true"
      :visible.sync="drawer.status.status"
      :with-header="false"
      :ref="drawer.status.name"
    >
      <Status :drawer-name="drawer.status.name" />
    </el-drawer>

    <el-drawer
      size="50%"
      :append-to-body="true"
      :visible.sync="drawer.provider.status"
      :with-header="false"
      :ref="drawer.provider.name"
      :before-close="beforeClose"
    >
      <Provider :drawer-name="drawer.provider.name" />
    </el-drawer>

    <el-drawer
      size="70%"
      :append-to-body="true"
      :visible.sync="drawer.contract.status"
      :with-header="false"
      :ref="drawer.contract.name"
      :before-close="beforeClose"
    >
      <Contract :drawer-name="drawer.contract.name" />
    </el-drawer>
  </div>
</template>
<script>
import Status from "./status-modal";
import Provider from "@/views/warehouseProvider/components/crm-create";
import Contract from "@/views/contractWarehouses/components/crm-create";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";
export default {
  components: { Status, Provider, Contract },
  mixins: [form, drawer, drawerChild],
  data() {
    return {
      selectedProduct: null,
      drawer: {
        status: {
          name: "status",
          status: false
        },
        provider: {
          name: "provider",
          status: false
        },
        contract: {
          name: "contract",
          status: false
        }
      }
    };
  },
  async mounted() {
    if (this.suppliers && this.suppliers.length === 0)
      await this.loadSuppliers();
    if (this.statuses && this.statuses.length === 0) await this.loadStatuses();
    if (this.supplier_contracts && this.supplier_contracts.length === 0)
      await this.loadSupplierContracts();
    if (this.products && this.products.length === 0) await this.loadProducts();
    if (this.currencies && this.currencies.length === 0)
      await this.loadCurrencies();
    if (this.measurements && this.measurements.length === 0)
      await this.loadMeasurements();
  },
  computed: {
    ...mapGetters({
      username: "auth/name",
      model: "procurements/model",
      rules: "procurements/rules",
      columns: "procurements/columns",
      suppliers: "suppliers/inventory",
      statuses: "statuses/inventory",
      supplier_contracts: "supplierContracts/inventory",
      products: "products/inventory",
      currencies: "currencies/inventory",
      measurements: "measurements/inventory"
    }),
    getTotal() {
      if (this.procurementProducts.length != 0 && this.procurementProducts) {
        return this.procurementProducts.reduce(
          (accumulator, currentValue) =>
            accumulator +
            currentValue.quantity *
              currentValue.price *
              this.getRate(currentValue.currency_id),
          0
        );
      }
      return 0;
    },
    procurementProducts() {
      return [...this.form.old_items, ...this.form.new_items];
    }
  },
  methods: {
    ...mapActions({
      save: "procurements/store",
      update: "procurements/update",
      deleteItem: "procurements/destroyItem",
      editModel: "procurements/show",
      empty: "procurements/empty",
      loadSuppliers: "suppliers/inventory",
      loadStatuses: "statuses/inventory",
      loadSupplierContracts: "supplierContracts/inventory",
      loadProducts: "products/inventory",
      loadCurrencies: "currencies/inventory",
      loadMeasurements: "measurements/inventory"
    }),
    async beforeClose(done) {
      await this.loadSuppliers();
      await this.loadStatuses();
      await this.loadSupplierContracts();
      await this.loadProducts();
      await this.loadCurrencies();
      await this.loadMeasurements();
      done();
    },
    getName(id, from) {
      const data = this[from].find(item => {
        return item.id == id;
      });
      return data ? data.name : "";
    },
    getRate(id) {
      const data = this.currencies.find(item => {
        return item.id == id;
      });
      return data ? data.rate : "";
    },
    selectProduct(id) {
      const product = this.products.find(product => product.id === id);
      this.form.new_items.push({
        name: product.name,
        product_id: product.id,
        quantity: 0,
        measurement_id: product.measurement_id,
        price: product.price,
        rate: this.getRate(product.currency_id),
        currency_id: product.currency_id,
        isOld: false
      });
      this.selectedProduct = null;
    },
    removeProduct(item) {
      if (item.isOld) {
        const index = this.form.old_items.indexOf(item);
        this.deleteItem(this.form.old_items[index].id)
          .then(res => {
            this.$alert(res);
          })
          .catch(err => this.$alert(err))
          .finally(() => {
            this.form.old_items.splice(index, 1);
          });
      } else {
        const index = this.form.new_items.indexOf(item);
        this.form.new_items.splice(index, 1);
      }
    },
    submitForm(resetForm = false) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loadingButton = true;
          this.update(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res);
            })
            .catch(err => {
              this.loadingButton = false;
              this.$alert(err);
            })
            .finally(() => {
              if (resetForm) {
                this.$refs["form"].resetFields();
                this.empty();
              }
            });
        }
      });
    }
  }
};
</script>
